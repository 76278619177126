import React, { useEffect } from "react";
import "./About.scss";
import { Grid, Typography } from "@mui/material";
import logo from "../../logo.jpg";
import Mission from "../../Img/teamwork-concept-with-hands.jpg";
import Princesir from "../../Img/Prince_sir.png";
import AOS from "aos";
import Footer from "../../Components/Footer/Footer";
import { easeIn, motion } from "framer-motion";
import ClientFocus from "../../Img/target-audience.png";
import Excellence from "../../Img/excellence.png";
import Innovation from "../../Img/idea.png";
import Whatsapp from "../../Components/Whatsapp/Whatsapp";

const About = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <motion.div
      className="about"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { duration: 0.5, easeIn } }}
    >
      <span className="whatsapp">
        <Whatsapp />
      </span>

      <header className="he">
        <Grid container>
          <Grid item lg={6} align="center" justify="center" id="grd">
            <div className="behind">
              <p id="Know">Who Are We</p>
              <p id="whoarewe">
                We are India’s rising company specializing in web
                development, Zoho, and recruitment services, powered
                by a skilled team of developers
              </p>
            </div>
          </Grid>
          <Grid item lg={6}id="grdi">
            <img src={logo} alt="" id="land" />
          </Grid>
        </Grid>
      </header>
      <div className="ourMission" data-aos="fade-up">
        <div className="ourMissionText">
          <Typography variant="h3" id="ourMission" data-aos="fade-up">
            Our Mission/Vision
          </Typography>
          <Typography variant="h6" id="ourMissionText" data-aos="fade-up">
            GiekyTribe is on a mission to redefine success in the IT world. We
            believe in a business approach that blends profit with ethics and
            customer-centric values. Our core philosophy centers around an
            integrated ecosystem that encompasses cutting-edge software
            solutions, comprehensive education, and a thriving community.
            Through this synergy, we empower businesses to grow responsibly and
            sustainably, aligning their bottom line with their social and moral
            compass. We're here to prove that companies can prosper with a
            conscience and soul intact. At GiekyTribe, we're committed to
            forging strong customer relationships, fostering meaningful
            connections, and making a positive impact in the ever-evolving IT
            landscape.
          </Typography>
        </div>
        <div className="ourMissionPhoto">
          <div className="tesing">
            <img src={Mission} alt="" id="im" data-aos="fade-up" />
          </div>
        </div>
      </div>
      <div className="ourStory" data-aos="fade-up">
        <div className="storyText">
          <Typography variant="h3" id="story" data-aos="fade-up">
            Our Story
          </Typography>
          <Typography variant="h6" id="storyText" data-aos="fade-up">
            Founded in April 2023 by Mr. Prince Mishra in India, GiekyTribe
            swiftly became a frontrunner in the digital landscape. Operating
            from their headquarters at 441, Tricity Plaza, Peer Muchalla Rd,
            Sector 20, Sanauli, Punjab 160104, the company excelled in web
            development, web design, Zoho development, and recruitment services.
            Business partner Ms. Simran Kaur played an integral role in shaping
            GiekyTribe's success story. With a commitment to innovation,
            integrity, and inclusivity, GiekyTribe continues to make a lasting
            impact in the tech industry.
          </Typography>
        </div>
        <div className="ourMissionPhoto">
          <div className="tesing">
            <img src={Princesir} alt="" id="im" data-aos="fade-right" />
          </div>
        </div>
      </div>
      <div className="ourCommmunity">
        <div className="community">
          <Typography variant="h3" id="community" data-aos="fade-up">
            Our Values
          </Typography>
        </div>
        <div className="cardss">
          <div className="card">
            <img src={ClientFocus} alt="" id="cardImage" />
            <Typography variant="h4" id="cardHeading" data-aos="">
              Client Focus
            </Typography>
            <Typography variant="h7" id="cardContent" data-aos="fade-up">
              GiekyTribe is dedicated to achieving excellence in every facet of
              our operations. We uphold rigorous personal and organizational
              standards and continually endeavor to surpass them. Our core
              belief at GiekyTribe centers around the profound impact of
              transparent and candid communication. We recognize that trust is
              the cornerstone of any enduring and successful partnership, be it
              with our valued team members or our esteemed clients.
            </Typography>
          </div>
          <div className="card">
            <img src={Excellence} alt="" id="cardImage" />

            <Typography variant="h4" id="cardHeading" data-aos="">
              Excellence
            </Typography>
            <Typography variant="h7" id="cardContent" data-aos="fade-up">
              GiekyTribe navigates with unwavering determination towards our
              organizational objectives, prioritizing the processes and outcomes
              of utmost significance. Our concentration is resolute and
              unwavering, fueled by unswerving dedication and fervor. Our
              mindset, strategic approach, and team are all aligned towards
              delivering unparalleled advantages to your business clientele. We
              are committed to assisting our clients in accomplishing remarkable
              feats, fostering mutual growth and advancement.
            </Typography>
          </div>
          <div className="card">
            <img src={Innovation} alt="" id="cardImage" />

            <Typography variant="h4" id="cardHeading" data-aos="">
              Innovation
            </Typography>
            <Typography variant="h7" id="cardContent" data-aos="fade-up">
              At GiekyTribe, we foster a dynamic and inventive work atmosphere,
              encouraging continuous self-improvement in our processes and
              methodologies. We take immense pride in our commitment to
              delivering services and products that are not only exceptional but
              also unwaveringly dependable. Our firm conviction is that
              adaptability paves the path to excellence, and we customize all
              our offerings to cater specifically to the unique requirements of
              each valued client.
            </Typography>
          </div>
        </div>
      </div>
      <Footer />
    </motion.div>
  );
};

export default About;
