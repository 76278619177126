import React, { useEffect } from "react";
import logo from "../../logo.jpg";
import "./Career.scss";
import {
  CardContent,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { easeIn, motion } from "framer-motion";
import hiring from "../../Img/handshake.jpg";
import AOS from "aos";
import "aos/dist/aos.css";
import Footer from "../../Components/Footer/Footer";
import { Card } from "react-bootstrap";
import {
  DataObject,
  Face3,
  Feed,
  Laptop,
  South,
  Terminal,
  TrendingFlat,
} from "@mui/icons-material";
import Whatsapp from "../../Components/Whatsapp/Whatsapp";
// import emailjs from "emailjs-com"
import WhatsappsPhotu from "../../Img/Whatsapp.png";
import LinkedIN from "../../Img/Linkedin.png";
import Email from "../../Img/Email.png";

const Career = () => {
  const [selectedJob, setSelectedJob] = React.useState("");

  const handleJobChange = (event) => {
    setSelectedJob(event.target.value);
  };

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   emailjs.sendForm( 'service_jo8zd0e','template_pk1saa4',event .target,'cH9pYTTlKCP60AHXJ').then(res=>{
  //     alert("Form Sbmitted Successfully");
  //     window.location.reload();
  //   }).catch(err=>{
  //     console.log(err)
  //   })

  // };

  useEffect(() => {
    AOS.init({ duration: 600 });
  }, []);
  return (
    <motion.div
      className="Career"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { duration: 0.5, easeIn } }}
    >
      <span className="whatsapp">
        <Whatsapp />
      </span>
      <header className="hes">
        <Grid container>
          <Grid item lg={6} align="center" justify="center" id="grd">
            <div className="behind">
              <p id="Know">Join Our Team</p>
              <p id="whoarewe">
                Join our team of talented professionals and contribute to
                innovative projects.
              </p>
            </div>
          </Grid>
          <Grid item lg={6} id="grdi">
            <img src={logo} alt="" id="land" />
          </Grid>
        </Grid>
      </header>

      <div className="wearehiring" data-aos="fade-up">
        <div className="ourMissionText">
          <Typography variant="h3" id="ourMission" data-aos="fade-up">
            We Are Hiring
          </Typography>
          <Typography variant="h6" id="ourMissionText" data-aos="fade-up">
            We are actively seeking visionary individuals with the capacity to
            pioneer transformative ideas for the betterment of our world. If you
            are among those who excel at carving innovative paths in highly
            competitive domains, we invite you to engage with our organization.
            There is no time to delay. Seize the opportunity to join our
            esteemed team. We eagerly anticipate the insights and proposals you
            can bring to the table.
          </Typography>
        </div>
        <div className="ourMissionPhoto">
          <div className="tesing">
            <img src={hiring} alt="" id="im" data-aos="fade-up" />
          </div>
        </div>
      </div>
      <div className="hiringProcess">
        <Typography variant="h3" id="hiringHead" data-aos="fade-up">
          Hiring Process
        </Typography>
        <div className="cards" data-aos="fade-up">
          <div className="cardcontent">
            <Feed sx={{ color: "#ff8906", fontSize: "6rem" }} />
            <div className="cardcontentpara">
              <Typography variant="h5" sx={{ color: "#ff8906" }}>
                1. Resume Shortlisting
              </Typography>
            </div>
          </div>

          <TrendingFlat
            sx={{ color: "#ff8906", fontSize: "6rem" }}
            id="hide"
            data-aos="fade-up"
          />
          <South
            sx={{ color: "#ff8906", fontSize: "6rem" }}
            id="hideit"
            data-aos="fade-up"
          />

          <div className="cardcontent" data-aos="fade-up">
            <Face3 sx={{ color: "#ff8906", fontSize: "6rem" }} />
            <div className="cardcontentpara">
              <Typography variant="h5" sx={{ color: "#ff8906" }}>
                2. HR Round
              </Typography>
            </div>
          </div>

          <TrendingFlat
            sx={{ color: "#ff8906", fontSize: "6rem" }}
            id="hide"
            data-aos="fade-up"
          />
          <South
            sx={{ color: "#ff8906", fontSize: "6rem" }}
            id="hideit"
            data-aos="fade-up"
          />

          <div className="cardcontent" data-aos="fade-up">
            <DataObject sx={{ color: "#ff8906", fontSize: "6rem" }} />
            <div className="cardcontentpara">
              <Typography variant="h5" sx={{ color: "#ff8906" }}>
                3.Technical Round
              </Typography>
            </div>
          </div>

          <TrendingFlat
            sx={{ color: "#ff8906", fontSize: "6rem" }}
            id="hide"
            data-aos="fade-up"
          />
          <South
            sx={{ color: "#ff8906", fontSize: "6rem" }}
            id="hideit"
            data-aos="fade-up"
          />

          <div className="cardcontent" data-aos="fade-up">
            <Laptop sx={{ color: "#ff8906", fontSize: "6rem" }} />
            <div className="cardcontentpara">
              <Typography variant="h5" sx={{ color: "#ff8906" }}>
                4. Final Round
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div className="ApplyNow">
        <Typography variant="h3" data-aos="fade-up">
          Apply Now!
        </Typography>
        {/* <form className="form" onSubmit={handleSubmit}>
          <TextField
            id="outlined-basic"
            name="name"
            label="Full Name"
            variant="outlined"
            fullWidth
            required
            data-aos="fade-up"
          />
          <TextField
            id="outlined-basic"
            label="Email"
            name="email"
            variant="outlined"
            fullWidth
            required
            data-aos="fade-up"
          />
          <TextField
            id="outlined-basic"
            label="Phone"
            name="phone"
            variant="outlined"
            fullWidth
            required
            data-aos="fade-up"
          />
          <TextField
            id="outlined-basic"
            label="Address"
            name="address"
            variant="outlined"
            fullWidth
            required
            data-aos="fade-up"
          />

          <TextField
            id="outlined-select-job"
            select
            label="Select a Job"
            name="job"
            value={selectedJob}
            onChange={handleJobChange}
            variant="outlined"
            fullWidth
            required
            data-aos="fade-up"
          >
            {[
              "Zoho Developer",
              "Full Stack developer",
              "Frontend developer",
              "Backend developer",
              "HR Manager",
              "Wordpress developer",
            ].map((job) => (
              <MenuItem key={job} value={job}>
                {job}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            id="outlined-basic"
            label="Years of Experience relevant to the application"
            variant="outlined"
            fullWidth
            name="experience"
            required
            data-aos="fade-up"
          />
          <TextField
            id="outlined-basic"
            label="Message"
            name="message"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            required
            data-aos="fade-up"
          />

          <button type="submit" className="submitButton" data-aos="fade-up">
            Submit
          </button>
        </form> */}
        <div className="whatsup">
          <a href="mailto:hr@giekytribe@gmail.com?subject=Job%20Application" target="_blank">
            <img src={Email} alt="" id="socialMedia" /><br />
            hr@giekytribe.com
          </a>
          <a
            href="https://www.linkedin.com/company/giekytribe-pvt-ltd/"
            target="_blank"
          >
            <img src={LinkedIN} alt="" id="socialMedia" />
          </a>

          <a href="https://wa.me/918287215081" target="_blank">
            <img src={WhatsappsPhotu} alt="" id="socialMedia" />
          </a>
        </div>
      </div>
      <Footer />
    </motion.div>
  );
};

export default Career;
