import React, { useState, useEffect, useRef } from "react";
import logo from "../../logo.jpg";
import "./Navbar.scss";
import { Typography, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [showNavLinks, setShowNavLinks] = useState(false);
  const navbarRef = useRef(null);

  const toggleNavLinks = () => {
    setShowNavLinks(!showNavLinks);
  };

  const hideNavLinks = () => {
    setShowNavLinks(false);
  };

  useEffect(() => {
    // Add a click event listener to the document
    const handleClickOutside = (event) => {
      if (
        navbarRef.current &&
        !navbarRef.current.contains(event.target) &&
        showNavLinks
      ) {
        // Click occurred outside the navbar while toggle is on
        hideNavLinks();
      }
    };

    // Attach the event listener when the component mounts
    document.addEventListener("click", handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showNavLinks]);

  return (
    <div className="custom-navbar" ref={navbarRef}>
      <div className="logo">
        <Link to="/" className="nav-link" onClick={hideNavLinks}>
          <img src={logo} alt="" id="logo" />
        </Link>
      </div>
      <div className={`nav-links ${showNavLinks ? "show" : ""}`}>
        <Link to="/" className="nav-link" onClick={hideNavLinks}>
          <Typography variant="h6" component="div" id="lnk">
            Home
          </Typography>
        </Link>
        <Link to="/about" className="nav-link" onClick={hideNavLinks}>
          <Typography variant="h6" component="div" id="lnk">
            About
          </Typography>
        </Link>
        <Link to="/services" className="nav-link" onClick={hideNavLinks}>
          <Typography variant="h6" component="div" id="lnk">
            Services
          </Typography>
        </Link>
        <Link to="/career" className="nav-link" onClick={hideNavLinks}>
          <Typography variant="h6" component="div" id="lnk">
            Career
          </Typography>
        </Link>
        <Link to="/contact-us" className="nav-link" onClick={hideNavLinks}>
          <button id="btns">Contact Us</button>
        </Link>
      </div>
      <IconButton
        edge="end"
        sx={{ color: "white" }}
        aria-label="menu"
        onClick={toggleNavLinks}
        id="menu-button"
      >
        <MenuIcon />
      </IconButton>
    </div>
  );
};

export default Navbar;
