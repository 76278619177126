import About from "./Pages/About/About";
import Home from "./Pages/Home/Home";
import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Services from "./Pages/Services/Services";
import Career from "./Pages/Career/Career";
import Contactus from "./Pages/Contactus/Contactus";

const AnimatedRoutes = () => {
  const location = useLocation();

  return (
    <div>
      <AnimatePresence>
        <Routes location={location} key={location.pathname}>
          <Route path="/" exact element={<Home />} key="home" />
          <Route path="/about" element={<About />} key="about" />
          <Route path="/services" element={<Services />} key="services" />
          <Route path="/career" element={<Career />} key="services" />
          <Route path="/contact-us" element={<Contactus />} key="contactus" />
        </Routes>
      </AnimatePresence>
    </div>
  );
};

export default AnimatedRoutes;
