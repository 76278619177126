import React, { useEffect } from "react";
import "./Footer.scss";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import { Facebook, Instagram, LinkedIn, Twitter } from "@mui/icons-material";

const Footer = () => {
  useEffect(() => {
    AOS.init({ duration: 600 });
  }, []);

  return (
    <>
      <footer id="footer" data-aos="fade-up">
        <div className="footer-section">
          <h3>GiekyTribe</h3>
          <p>
            Gieky Tribe is your one-stop destination for web development, Zoho
            development, web designing, and top-notch recruitment services. With
            a team of skilled professionals, we create stunning websites, tailor
            Zoho solutions, and offer expert recruitment support. Elevate your
            online presence and staffing solutions with Gieky Tribe today.
          </p>
          <div style={{display: 'flex', gap:"1rem"}}>
          <a href="https://www.linkedin.com/company/giekytribe-pvt-ltd/" target="_blank">
            <LinkedIn color="primary" sx={{marginLeft:"4rem"}}/>
          </a>
          <a href="https://www.linkedin.com/company/giekytribe-pvt-ltd/" target="_blank">
            <Instagram color="error" />
          </a>
          <a href="https://www.facebook.com/profile.php?id=61551747062431&mibextid=ZbWKwL" target="_blank">
            <Facebook color="primary" />
          </a>
          </div>
        </div>

        <div className="footer-section">
          <h3>Useful Links</h3>
          <ul>
            <Link to="/">
              <li>Home</li>
            </Link>
            <Link to="/about">
              <li>About</li>
            </Link>
            <Link to="/services">
              <li>Services</li>
            </Link>
            <Link to="/career">
              <li>Career</li>
            </Link>
            <Link to="/contact-us">
              <li>Contact Us</li>
            </Link>
          </ul>
        </div>

        <div className="footer-section">
          <h3>Reach us</h3>
          <address>
            441, Tricity Plaza
            <br />
            Peer Muchalla Rd, Sector 20, Sanauli, Punjab 160104
            <br />
            Email: info@giekytribe.com
            <br />
            Phone: 8287215081
          </address>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3432.0638006764193!2d76.8578281121281!3d30.66033187451343!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390f9492aacfffe5%3A0x4395fd5c0a539130!2sTricity%20Plaza!5e0!3m2!1sen!2sin!4v1694518819888!5m2!1sen!2sin"
            width="150"
            height="150"
            style={{ border: "0", borderRadius: "50%" }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>

        <div className="footer-section">
          <h3>Newsletter</h3>
          <p>Subscribe to our newsletter for updates.</p>
          <form>
            <input type="email" placeholder="Enter your email" />
            <button type="submit">Subscribe</button>
          </form>
        </div>
      </footer>
      <div className="copywrite">
        <p>
          &copy; {new Date().getFullYear()} GiekyTribe. All Rights Reserved.
        </p>
      </div>
    </>
  );
};

export default Footer;
