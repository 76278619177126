import React, { useEffect } from "react";
import "./Services.scss";
import AOS from "aos";
import { motion } from "framer-motion";
import Footer from "../../Components/Footer/Footer";
import { Grid, Typography } from "@mui/material";
import Mission from "../../Img/WhyUs.jpg";
import image from "../../logo.jpg";
import { Code, Computer, Web } from "@mui/icons-material";
import ReactIcon from "./Icons/ReactIcon";
import zoho from "../../Img/zoho-1.svg";
import Whatsapp from "../../Components/Whatsapp/Whatsapp";

const Services = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const servicesData = [
    {
      title: "Web Development",
      icon: <ReactIcon color="#ff8906" size={50} />,
      description:
        "We offers expert web development services to elevate your online presence. Our team excels in crafting top-notch websites that drive business growth. With a focus on innovation and user-centric design, we create digital solutions that captivate your audience. Whether you need a stunning website, e-commerce platform, or web app, we deliver results that exceed expectations. Trust GiekyTribe for all your web development needs, and watch your online success soar.",
    },
    {
      title: "Zoho development",
      icon: <img src={zoho} height="25px" style={{ marginBottom: ".5rem" }} />,
      description:
        "Zoho Development by GiekyTribe delivers tailored solutions to supercharge your business processes. Our expertise in Zoho's suite of applications empowers you to streamline operations, enhance productivity, and achieve your goals efficiently. Whether it's Zoho CRM customization, workflow automation, or integration, we've got you covered. Harness the full potential of Zoho with GiekyTribe's dedicated development services. Elevate your business and thrive in the digital age with our Zoho expertise at your side",
    },
    {
      title: "Web Designing",
      icon: <Code sx={{ color: "#ff8906", fontSize: "2rem" }} />,

      description:
        "GiekyTribe specializes in exquisite web design services that transform your online presence. Our talented designers craft visually stunning websites that engage your audience and leave a lasting impression. We focus on user-friendly layouts, responsive design, and creative aesthetics to ensure your website stands out. From concept to implementation, our web design team brings your vision to life, creating a digital masterpiece that resonates with your brand. Elevate your online identity with GiekyTribe's expert web design solutions.",
    },
    {
      title: "Recruitment Service",
      icon: <Computer sx={{ color: "#ff8906", fontSize: "2rem" }} />,

      description:
        "GiekyTribe offers comprehensive recruitment services that connect businesses with top talent. Our dedicated team understands your staffing needs and identifies candidates who align with your company's values and goals. With a deep network and rigorous vetting process, we source, screen, and present the best candidates for your consideration. Whether you're looking for permanent, temporary, or specialized hires, GiekyTribe's recruitment services streamline the hiring process, saving you time and resources. Trust us to find the right talent to drive your company's success.",
    },
   
  ];

  return (
    <motion.div
      className="services"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { duration: 0.5 } }}
    >
    <span className="whatsapp">
        <Whatsapp/>
      </span>
      <header className="ourServices">
        <Grid container>
          <Grid item lg={6} align="center" justify="center" id="grd">
            <div className="behind">
              <p id="brain">Our service sets us apart from the rest.</p>
            </div>
          </Grid>
          <Grid item lg={6} id="grdi">
            <img src={image} alt="" id="land" />
          </Grid>
        </Grid>
      </header>
      <div className="cards">
        <div className="card-container">
          {servicesData.map((service, index) => (
            <div className="service-card" data-aos="fade-up" key={index}>
              <div className="card-content">
                <div className="icon-container">{service.icon}</div>
                <h5 style={{ textAlign: "center" }}>{service.title}</h5>
                <p>{service.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="whyChooseUs">
        <Typography variant="h3" id="whyChoosseusT">
          Why Choose Us
        </Typography>
        <div id="whyChoose">
          <div id="whyChooseText">
            <Typography variant="h6" id="whyText" data-aos="fade-up">
              At GiekyTribe, we stand out as your premier choice for web
              development, Zoho development, and recruitment services. Our
              commitment to excellence is unwavering, making us the ideal
              partner for businesses and job seekers alike. For businesses, we
              offer expertise honed through years of crafting exceptional web
              solutions and optimizing business processes with Zoho development.
              Our client-centric approach ensures that your unique needs and
              objectives are at the forefront of every project. We deliver on
              time, every time, without compromising on quality. For job
              seekers, GiekyTribe provides a dynamic platform for growth and
              learning. Join a collaborative culture that values your
              contributions and encourages professional development. Diverse
              projects and competitive benefits make us the perfect place to
              launch or advance your career. Choose GiekyTribe, where innovation
              meets dedication, and where your success is our top priority.
              Experience the difference with a team that's committed to
              delivering excellence in every endeavor.
            </Typography>
          </div>
          <div className="whyTextPhoto">
            <div className="testing">
              <img src={Mission} alt="" id="ima" data-aos="fade-up" />
            </div>
          </div>
        </div>
      </div>
      <div className="startProject" data-aos="fade-up">
        <div className="ourProjectText">
          <Typography variant="h3" id="ourProjectText" data-aos="fade-up">
            Testing and QA
          </Typography>
          <Typography variant="h6" id="ourProjectPara" data-aos="fade-up">
            Our teams arrive with a wealth of experience and an unwavering
            focus on your immediate business challenges, all while meticulously
            examining the fundamental technological underpinnings. This sets us
            apart from the rest, enabling us to swiftly provide effective
            solutions and, at the same time, guide the project towards
            addressing long-term implications. We make a steadfast commitment to
            deliver top-notch services and attend to your requirements with
            unwavering dedication. Our dedicated teams bring a wealth of
            experience and an unyielding focus on your immediate business
            challenges, all while meticulously examining the fundamental
            technological underpinnings. This sets us apart from the rest,
            enabling us to swiftly provide effective solutions and, at the same
            time, guide the project towards addressing long-term implications.
            We then integrate these solutions into your product during
            subsequent sprint cycles, ensuring a holistic approach to your
            needs.
          </Typography>
        </div>
        <div className="workWithUs"></div>
      </div>
      <Footer />
    </motion.div>
  );
};

export default Services;
