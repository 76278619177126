import React, { useState, useEffect, useRef } from 'react';
import './Whatsapp.scss';
import Whatsapps from '../../Img/Whatsapp.png';
import call from '../../Img/Call.png';
import Contact from '../../Img/contact.png';

const Whatsapp = () => {
  const [showIcons, setShowIcons] = useState(false);
  const iconContainerRef = useRef(null);

  const toggleIcons = () => {
    setShowIcons((prev) => !prev);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (iconContainerRef.current && !iconContainerRef.current.contains(event.target)) {
        setShowIcons(false);
      }
    };

    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <span ref={iconContainerRef}>
      <a href="https://wa.me/918287215081" target="_blank">
        <img
          src={Whatsapps}
          alt=""
          id="whatsapp"
          className={`whatsappIcon ${showIcons ? 'show' : ''}`}
          onClick={toggleIcons}
        />
      </a>
      <a href="tel:+918287215081" target="_blank">
        <img
          src={call}
          alt=""
          id="whatsapp"
          className={`callIcon ${showIcons ? 'show' : ''}`}
          onClick={toggleIcons}
        />
      </a>
      <img src={Contact} alt="" id="whatsapp" className="conImg" onClick={toggleIcons} />
    </span>
  );
};

export default Whatsapp;
