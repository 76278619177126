import React, { useEffect, useState } from "react";
import "./Contact.scss";
import AOS from "aos";
import "aos/dist/aos.css";
import { motion } from "framer-motion";
import { Grid, TextField, Typography } from "@mui/material";
import logo from "../../logo.jpg";
import Footer from "../../Components/Footer/Footer";
import { Call, Email, PinDrop } from "@mui/icons-material";
import Whatsapp from "../../Components/Whatsapp/Whatsapp";
import WhatsappsPhotu from "../../Img/Whatsapp.png";
import LinkedIN from "../../Img/Linkedin.png";
import Emailadd from "../../Img/Email.png";

const Contactus = () => {
  useEffect(() => {
    AOS.init({ duration: 600 });
  }, []);

  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    message: "",
  });

  const [formErrors, setFormErrors] = useState({
    fullName: "",
    email: "",
  });

  const validateEmail = (email) => {
    // Simple email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Reset form errors
    setFormErrors({
      fullName: "",
      email: "",
    });

    let isValid = true;

    // Validate fullName
    if (formData.fullName.trim() === "") {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        fullName: "Full Name is required",
      }));
      isValid = false;
    }

    // Validate email
    if (formData.email.trim() === "") {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        email: "Email is required",
      }));
      isValid = false;
    } else if (!validateEmail(formData.email)) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        email: "Invalid email format",
      }));
      isValid = false;
    }

    // If the form is valid, you can submit it or perform further actions
    if (isValid) {
      // Form submission logic
      // Example: You can send the form data to your backend or perform other actions
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Clear the error message for the current field
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  return (
    <motion.div
      className="Contactus"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { duration: 0.5 } }}
    >
      <span className="whatsapp">
        <Whatsapp />
      </span>
      <header className="he">
        <Grid container>
          <Grid item lg={6} align="center" justify="center" id="grd">
            <div className="behind">
              <p id="Know">Have Questions? We Have Answers.</p>
            </div>
          </Grid>
          <Grid item lg={6} id="grdi">
            <img src={logo} alt="" id="land" />
          </Grid>
        </Grid>
      </header>
      <div className="section">
        <div className="centerka">
          <div className="Contact-us">
            <Typography variant="h3" data-aos="fade-up" id="contact">
              GET IN TOUCH
            </Typography>
            <Typography variant="h7" data-aos="fade-up" id="contactus">
              Get closer to your goals with better communication.
            </Typography>
            <div className="whatsup">
              <a
                href="mailto:hr@giekytribe@gmail.com?subject=Job%20Application"
                target="_blank"
              >
                <img src={Emailadd} alt="" id="socialMedia" />
                <br />
                hr@giekytribe.com
              </a>
              <a
                href="https://www.linkedin.com/company/giekytribe-pvt-ltd/"
                target="_blank"
              >
                <img src={LinkedIN} alt="" id="socialMedia" />
              </a>

              <a href="https://wa.me/918287215081" target="_blank">
                <img src={WhatsappsPhotu} alt="" id="socialMedia" />
              </a>
            </div>
          </div>
          <div className="map">
            <div id="map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3432.0638006764193!2d76.8578281121281!3d30.66033187451343!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390f9492aacfffe5%3A0x4395fd5c0a539130!2sTricity%20Plaza!5e0!3m2!1sen!2sin!4v1694518819888!5m2!1sen!2sin"
                width="400"
                height="400"
                style={{ border: "0" }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
            <div className="details">
              <Typography variant="h4">Reach Us</Typography>
              <Typography variant="h7" sx={{ width: "50%" }} id="reach">
                <PinDrop /> &nbsp; 441, Tricity Plaza Peer Muchalla Rd, Sector
                20, Sanauli, Punjab 160104
              </Typography>
              <Typography variant="h7" id="reach">
                <Email />
                &nbsp; info@giekytribe.com
              </Typography>
              <Typography variant="h7" id="reach">
                <Call />
                &nbsp; 8287215081
              </Typography>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </motion.div>
  );
};

export default Contactus;
