import React, { useEffect } from "react";
import "./Home.scss";
import { Grid, Typography } from "@mui/material";
import PrinceSir from "../../Img/Prince Sir.jpg";
import SimranMam from "../../Img/Simran mam.jpeg";
import AOS from "aos";
import image from "../../logo.jpg";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import { Button, Carousel } from "react-bootstrap";
import "aos/dist/aos.css";
import { motion } from "framer-motion";
import HR from "../../Img/HR.jpg"
import Vinod_Sir from "../../Img/VInod Sir.jpg"
import { Link } from "react-router-dom";
import AKY from "../../Img/AKY.jpg";
import James from "../../Img/James.png"
import { LinkedIn } from "@mui/icons-material";
import Footer from "../../Components/Footer/Footer";
import Whatsapp from "../../Components/Whatsapp/Whatsapp";
import Mohd from "../../Img/Mohd Dallal.jpg"
import BP from "../../Img/BlueprintDigital.jpeg"
import JL from "../../Img/JL.jpg"

const Home = () => {
  useEffect(() => {
    AOS.init({ duration: 600 });
  }, []);

  const testimonialsData = [
    {
      name: " James Fleming ",
      testimony:
        `We've had the pleasure of working with GiekyTribe Pvt. Ltd for over a year now. They masterfully designed our Zoho CRM, streamlined our sales process, and integrated Zoho People into our operations. Their diligence, expertise, and consistency truly stand out. We regard them as more than just service providers; they've become invaluable business partners.
        If you're looking for experts who truly understand the ins and outs of ZOHO, look no further than GiekyTribe. Highly recommended!
        `,
      image: James,
      backgroundColor: "#9d789b",
    },
    {
      name: "Muhammad Talal",
      testimony:
        "GiekyTribe team helped me with their expertise in setting up and customizing our Zoho CRM was truly exceptional. GiekyTribe's creative solutions, technical support, and prompt responsiveness with a big smile have been invaluable to our startup. I recommend GiekyTribe . for any business looking for top-notch CRM solutions. Thank you for your outstanding service!",
      image: Mohd,
    },
    {
      name: "Blueprint Digital",
      testimony:
        "GiekyTribe is very professional and completed the project successfully. They helped us greatly with our Zoho workflow and we would highly reccomend them to others. We wish them well in their future endeavors and will definitely work with them again in the future should the need arise.",
      image: BP,
    },
    {
      name: "JL Closets",
      testimony:
        "It was a wonderful experience working with GiekyTribe. Did exactly what needed to be done. highly recommend anyone that's looking for a ZOHO Expert!",
      image: JL,
    },
  ];

  
const teamMembers = [
  {
    name: "Mr. Vinod Kumar",
    designation: "Full Stack Developer",
    imageSrc: Vinod_Sir,
    linkedin: "https://www.linkedin.com/in/vinod-kumar-4b208b28b/",
  },
 ,
  {
    name: "Mr. Abhishek Kumar Yadav",
    designation: "Frontend Developer",
    imageSrc: AKY,
    linkedin: "https://www.linkedin.com/in/abhishek-kr-yadav-b92918211/",
  },

  {
    name: "Mrs. Priyamda",
    designation: "HR ",
    imageSrc: HR,
    linkedin: "https://www.linkedin.com/in/priyambda-mishra-6a99361aa/",
  },
];

  return (
    <motion.div
      className="home"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { duration: 0.5 } }}
    >
    <span className="whatsapp">
        <Whatsapp/>
      </span>
      <header id="lhead">
        <Grid container>
          <Grid item lg={6} align="center" justify="center" id="grd">
            <div className="behind">
              <p id="brain">Brain Behind The Technology</p>
              <Link to="/about">
                <button id="btn">Know More</button>
              </Link>
            </div>
          </Grid>
          <Grid item lg={6} id="grdi">
            <img src={image} alt="" id="land" />
          </Grid>
        </Grid>
      </header>
      <div className="con" data-aos="fade-up">
        <p id="suc">
          Upwork recognizes our consistent excellence with a 100% success rate
          and a top rating.
        </p>
        <p id="or">
          Our impressive statistics speak volumes about our performance. Project
          after project, we maintain an impeccable track record with 100%
          delivery and 5-star ratings. When you can hire the best, why consider
          anyone else?
        </p>
        <a
          href="https://www.upwork.com/freelancers/~0168477ec84d90889f"
          target="_blank"
        >
          <button id="btn">Portfolio</button>
        </a>
      </div>
      <div className="hireusfor" data-aos="fade-up">
        <p id="hire">Hire Us For</p>
        <div className="card-container">
          <div className="service-card" data-aos="fade-up">
            <div className="card-content">
              <h5>Software Development</h5>
              <p>
                GiekyTribe specializes in transformative software development,
                leveraging cutting-edge technologies to create tailored
                solutions that enhance business processes and drive growth. Our
                expert team crafts innovative applications and systems, ensuring
                seamless integration and improved operational efficiency for
                your organization's unique needs.
              </p>
            </div>
          </div>
          <div className="service-card" data-aos="fade-up">
            <div className="card-content">
              <h5>Recruitment Service</h5>
              <p>
                GiekyTribe's recruitment service is your trusted partner in
                talent acquisition. We connect businesses with exceptional
                professionals across various industries. Our rigorous screening
              
                and personalized approach ensure you find the right candidates
                to fuel your company's success. Streamline your hiring process
                and build a high-performing team with us.
              </p>
            </div>
          </div>
  
        </div>
        <Link to="/services">
          <button id="btn" data-aos="fade-up">
            Explore More
          </button>
        </Link>
      </div>

      <div className="ourcomp" data-aos="fade-up">
        <Typography variant="h4" id="aboutc">
          About Our Company
        </Typography>
        <Typography variant="body1" id="abouto" data-aos="fade-up">
          GiekyTribe Pvt. Ltd. is an India-based Company, our flexible team
          works on exciting projects for every national as well as international
          clients. With years of experience, we have outdone ourselves in every
          project we have tackled. Whether it has involved Web & App Development
          or e-Commerce work. We love what we do, and that's what makes our team
          special. We have successfully worked with various MVC frameworks such
          as Python and Java, and we are proficient in databases like Oracle,
          SQL, Postgres, and MySQL. Our proficiency in front-end technologies
          like Vue.js, React, and Angular has exposed us to a wide range of
          challenges, allowing us to acquire valuable skills in cutting-edge
          technology domains. Our Organisation specialises in Zoho Apps, website
          design and development, mobile apps, solution designing and
          development, database designing, custom software, and product
          development. GiekyTribe aims to deliver high quality solution to their
          clients around the Globe by fulfilling their constantly changing
          business needs. We assure best quality to our clients at all times.
          Our motto is to keep our clients on the leading edge of information
          technology by adding significant value to their business. Most
          importantly, we accomplish this by creating long-term relationships
          with each partner / client by combining creative and cost effective
          solutions with a solid foundation of support and maintenance. What
          really makes a company flourish are its people. We believe that
          everyone from amateur designer to an expert application software
          developer can do well creatively, provided they are given an
          environment to grow. Our project managers are smart enough to allocate
          the work in a way that everyone learns something new out of it,
          without compromising the speed of the project. We're dedicated to
          building bespoke software and applications that can get into as many
          hands as possible.
        </Typography>
        <div className="founder" data-aos="fade-up">
          <div
            className="Prince"
            // data-aos="fade-up"
            style={{
              background: "#0f0e17",
              borderRadius: "20px",
              padding: "20px",
              color: "#fffffe",
            }}
          >
            <img src={PrinceSir} alt="" id="founder" />
            <Typography variant="h6" id="prince">
              Mr. Prince Mishra
            </Typography>
            <Typography variant="subtitle1" id="founder-role">
              Founder & Director
            </Typography>
            <a
              href="https://www.linkedin.com/in/prince-mishra-888576281/"
              target="_blank"
            >
              <LinkedIn />
            </a>
          </div>
          <div
            className="Simran"
            // data-aos="fade-up"
            style={{
              background: "#0f0e17",
              borderRadius: "20px",
              padding: "20px",
              color: "#fffffe",
            }}
          >
            <img src={SimranMam} alt="" id="founder" />
            <Typography variant="h6" id="simran">
              Ms. Simran Kaur
            </Typography>
            <Typography variant="subtitle1" id="founder-role">
            Founder & Director
            </Typography>
            <a
              href="https://www.linkedin.com/in/simran-kaur-75b2941a2/"
              target="_blank"
            >
              <LinkedIn />
            </a>
          </div>
        </div>
      </div>
      <div className="ourTeam" data-aos="fade-up">
        <Typography variant="h3" id="ourTeamTitle">
          Together we're stronger
        </Typography>
        <Typography variant="h5" id="ourTeamTitleInfo">
          GiekyTribe embodies a dynamic group of professionals, each
          distinguished by their extensive expertise and a flair for innovation.
          We pride ourselves on our ability to cater to our clients'
          multifaceted requirements, thanks to our team's rich experience and
          creative prowess. Our commitment to delivering outstanding outcomes
          consistently sets us apart. With a focus on excellence, GiekyTribe is
          your trusted partner in achieving your software development, Zoho
          development, web designing, and recruitment service objectives. Our
          talented and dedicated professionals are here to transform your ideas
          into reality and help your business thrive.
        </Typography>
        <div className="team-cards" data-aos="fade-up">
        {teamMembers.map((member, index) => (
          <div className="team-card" data-aos="zoom-in" key={index}>
            <img src={member.imageSrc} alt={member.name} />
            <div className="card-info">
              <Typography variant="h6" id="card-name">
                {member.name}
              </Typography>
              <Typography variant="subtitle1" id="card-desig">
                {member.designation}
              </Typography>
              <a href={member.linkedin} target="_blank" rel="noopener noreferrer">
                <LinkedIn />
              </a>
            </div>
          </div>
        ))}
        </div>
      </div>
      <div className="testimonials-container" data-aos="fade-up">
        <h2>Testimonials</h2>
        <Carousel interval={2500} data-bs-theme="dark" >
          {testimonialsData.map((testimonial, index) => (
            <Carousel.Item key={index}>
              <div className="testimonial">
                <p className="testimonial-text">{testimonial.testimony}</p>
                <div className="client-info">
                  <img
                    className="client-image"
                    src={testimonial.image}
                    alt={testimonial.name}
                  />
                  <p className="client-name">{testimonial.name}</p>
                </div>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
      <div className="hireus">
        <div id="hireus">
          <Grid container>
            <Grid item lg={8}></Grid>
          </Grid>
        </div>
      </div>

      <Footer/>
    </motion.div>
  );
};

export default Home;
